import React from 'react';
import './Cards.css';
import CardLink from './CardLink';

function AccomodationCards() {
  return (
    <div className='cards'>
      <h1>
        Alojamientos
      </h1>
      <p>
        Hemos recopilado algunos hoteles céntricos y seguros. También les compartimos algunos de Les Corts. Algunos sitios ofrecen descuentos por "early booking" si lo hacen con tiempo, incluyendo en Airbnb y Booking.com. Les aninamos a reservar lo más pronto posible y a ponerse en contacto con otros invitados para armar viajes en grupo. Saldrá más a cuenta.
      </p>
      <div className='cards__container'>
        <div className='cards__wrapper'>
           <ul className='cards__items'>
              <CardLink
              src='images/Derby.png'
              text='Gran Derby Suite'
              label='Francesc Macià'
              description='Hotel cercano a la Plaza de Francesc Macià. Perfecto para quedarse la noche de la boda.'
              url='https://www.hotelgranderby.com/es'
              />
              <CardLink
              src='images/Melia.png'
              text='Meliá Hotels Barcelona'
              label='Francesc Macià'
              description='Consigue hasta un 25% de descuento. En el apartado "Código de Empresa" introduce 609081AEF. Plazas limitadas.'
              url='https://meliapro.melia.com/es/corporate'
              />
        </ul>
        <ul className='cards__items'>
              <CardLink
              src='images/AirbnbPlFr1.png'
              text='Airbnb.com - Hasta 4 huéspedes'
              label='Francesc Macià'
              description='Apartamento con 3 habitaciones cerca de la Plaza de Francesc Macià.'
              url='https://www.airbnb.com/rooms/1282506140382258736'
              />
              <CardLink
              src='images/AirbnbPlFr2.png'
              text='Airbnb - Hasta 6 huéspedes'
              label='Francesc Macià'
              description='Apartamento con dos habitaciones dobles más un sofá-cama.'
              url='https://www.airbnb.com/rooms/2729680'
              />
              <CardLink
              src='images/AirbnbEix1.png'
              text='Airbnb - Hasta para 3 huéspedes'
              label="L'Eixample"
              description='Apartamento con una habitación cerca de Sants Estació.'
              url='https://www.airbnb.com/rooms/1333910478780944155'
              />
              <CardLink
              src='images/AirbnbEix2.png'
              text='Airbnb - Para 2 huéspedes'
              label="L'Eixample"
              description='Apartamento con una habitación cerca del Parc Joan Miró'
              url='https://www.airbnb.com/rooms/1150689290527843746'
              />
        </ul>
        <ul className='cards__items'>
            <CardLink
              src='images/AirbnbPlEs1.png'
              text='Airbnb.com - Hasta 4 huéspedes'
              label='Plaza España'
              description='Apartamento privado con capacidad para hasta 4 adultos cerca de Plaza España.'
              url='https://www.airbnb.com/rooms/26883321?'
            />
            <CardLink
              src='images/AirbnbEix3.png'
              text='Airbnb.com - Hasta 4 huéspedes'
              label="L'Eixample"
              description='Apartamento privado con capacidad para hasta 4 adultos cerca del Hospital Clinic. Tiene descuento de "Early Booking".'
              url='https://www.airbnb.com/rooms/1183802671723890953'
            />
            <CardLink
              src='images/Barcelo.png'
              text='Hotel Barceló - Barcelona Occidental 1929'
              label="Plaza España"
              description='Hotel muy cercano a Plaza España. También lo recomendamos para la noche de la boda.'
              url='https://www.barcelo.com/es-es/occidental-barcelona-1929/'
            />
          </ul>
          <ul className='cards__items'>
            <CardLink
              src='images/Fisa.png'
              text='Fisa Rentals'
              label='Les Corts'
              description='Apartamentos privados. Tienen varias opciones - con capacidad para 4, 3 o 2 adultos. Perfecto para viajes en grupo.'
              url='https://www.fisarentals.com/es/marques-sentmenat-alquiler-apartamentos-les-corts-barcelona/'
              />
              <CardLink
              src='images/Princep.png'
              text='Booking.com - Inside Barcelona Princep'
              label='Plaza España'
              description='Apartamentos privados. Tienen varias opciones - con capacidad para 4, 3 o 2 adultos. Perfecto para viajes en grupo.'
              url='https://www.booking.com/hotel/es/vivobarcelona-apartments.es.html'
              />
              <CardLink
              src='images/08028.png'
              text='Apartamentos 08028'
              label='Les Corts'
              description='Apartamentos privados. Tienen varias opciones - con capacidad para 4, 3 o 2 adultos. Perfecto para viajes en grupo.'
              url='https://www.08028apartments.com'
              />
        </ul>
        <ul className='cards__items'>
            <CardLink
              src='images/Atenea.jpg'
              text='Atenea Barcelona'
              label='Les Corts'
              url='https://www.cityhotels.es/aparthotel-atenea-barcelona/'
              />
              <CardLink
              src='images/NHLesCorts.jpg'
              text='Hotel NH - Les Corts'
              label='Les Corts'
              url='https://www.nh-hotels.com/es/hotel/nh-barcelona-les-corts'
              />
              <CardLink
              src='images/NHConstanza.jpg'
              text='Hotel NH - Constanza'
              label='Les Corts'
              url='https://www.nh-collection.com/en/hotel/nh-collection-barcelona-constanza'
              />
        </ul>
        <ul className='cards__items'>
            <CardLink
              src='images/POLHotel.jpg'
              text='Pol & Grace Hotel'
              label='Gràcia'
              description='Hotel curado por THE OVER en Gràcia. Para los que buscan un espacio creativo.'
              url='https://www.polgracehotel.es/'
            />
            <CardLink
              src='images/StMoritz.jpg'
              text='Hotel St. Moritz'
              label="L'Eixample"
              description='Hotel céntrico. Muy cerca de Passeig de Gràcia.'
              url='https://www.hcchotels.com/en/st-moritz/'
            />
          </ul>
        </div>
      </div>
    </div>
  )
}

export default AccomodationCards